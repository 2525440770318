import React from 'react'
import styled from 'styled-components'

const StyledBar = styled.div`
    display:grid;
    grid-template-columns:33% 33% 33%;
    justify-content:center;
    padding: 8px 0;
    > div:nth-child(2){
        text-align:center;
    }

    @media screen and (max-width: 767px){
        &{
            grid-template-columns: 1fr 1fr;
        }
        div:last-child{
            display:none;
        }
    }
`

const TopBar = () => {
  return (
    <div style={{borderBottom: "1px solid #C2B8B8"}}>
        <div className='maxWidth' >
            <StyledBar>
                <div>
                    Call Us : 9999999999/8888888888
                </div>
                <div>
                    Delivering PAN India
                </div>
                <div>

                </div>
            </StyledBar>
        </div>
    </div>
  )
}

export default TopBar