import React from 'react'
import styled from 'styled-components'
import Heading from '../Common/Heading'
import Products from '../../Data/Products.json'
import ProductCard from '../Common/ProductCard/ProductCard'

const StyledSection = styled.div`
  padding: var(--padVertical) 0;
  .productsWrapper{
    display:flex;
    justify-content:space-between;
    gap: 16px;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 767px){
    .productsWrapper{
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 767px){
    .productsWrapper{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
`

const SectionA = () => {
  return (
    <div>
      <div className='maxWidth'>
        <StyledSection>
          <Heading> Whole Spices and Masala </Heading>
          <div className='productsWrapper'>
            {Products.slice(0,4).map((value, index)=>{
              const imgFile = require(`../../Data/ProductImages/${value.slug}/${value.slug}.webp`);
              return <ProductCard key={value.slug + index} imgLink={imgFile} link={value.link} category={value.category.title} name={value.title} price={value.price.range} />
            })}
          </div>
        </StyledSection>
      </div>
    </div>
  )
}

export default SectionA