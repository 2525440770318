import React from 'react'
import styled from 'styled-components'

const StyledHeading = styled.h2`
    font-size: 2rem;
    text-align:center;
    color: var(--red);
    margin-bottom: 24px;
    @media screen and (max-width: 767px){
      &{
        margin-bottom: 16px;
      }
    }
`

const Heading = (props) => {
  return (
    <StyledHeading>
        {props.children}
    </StyledHeading>
  )
}

export default Heading