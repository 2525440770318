import React from 'react'
import styled from 'styled-components'
import Heading from '../Common/Heading'
import Products from '../../Data/Products.json'
import ProductCard from '../Common/ProductCard/ProductCard'
import {Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules';
import 'swiper/css'
import 'swiper/css/navigation';

const StyledSection = styled.div`
  padding: var(--padVertical) 0;
  .swiper {
    padding: 16px 0;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
    font-size: 28px!important;
    color:#9d9d9d;
  }
  @media screen and (min-width: 767px){
    .productsWrapper{
      padding: 0 20px;
    }
  }
`

const SectionB = () => {
    let limit = 0;
  return (
    <div>
      <div className='maxWidth'>
        <StyledSection>
          <Heading> Hot Selling Products! </Heading>
          <div className='productsWrapper'>
            <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={2}
            navigation
            breakpoints={{
              767:{
                slidesPerView: 3
              },
              1024:{
                slidesPerView: 4
              },
              1300:{
                slidesPerView: 5
              }
            }}
            >
              {
              Products.map((value, index)=>{
                if (limit !== 6 && value.tags.includes('hot')) {
                  const imgFile = require(`../../Data/ProductImages/${value.slug}/${value.slug}.webp`);
                  limit = limit + 1;
                  return <SwiperSlide key={value.slug + index} > <ProductCard imgLink={imgFile} link={value.link} category={value.category.title} name={value.title} price={value.price.range} />  </SwiperSlide>
                }
              })
              }
            </Swiper>
          </div>
        </StyledSection>
      </div>
    </div>
  )
}

export default SectionB