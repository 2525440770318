import React from 'react'
import styled from 'styled-components'
import icon from '../../Assets/buttonArrow.svg'

const StyledAPrimary = styled.a`
  border: 0px solid white;
  padding: 12px 32px;
  font-size: 1rem;
  line-height: 130%;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  gap: 8px;
  color:white;
  text-decoration:none;
  background: var(--red);
  border-radius: 8px;
`

const StyledASecondary = styled.a`
  border: 0px solid white;
  padding: 12px 32px;
  font-size: 1rem;
  line-height: 130%;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  gap: 8px;
  color:white;
  text-decoration:none;
  background: var(--orange);
  border-radius: 8px;
`

const ButtonLinkExternal = (props) => {
  return (
    <>
      {
        props.secondary ? 
        <StyledASecondary href={props.link}>
          {props.children} 
          {props.showIcon ? <img src={icon} alt="button icon right" width="20" height="20" /> : <></>}
        </StyledASecondary>
        :
        <StyledAPrimary href={props.link}>
          {props.children} 
          {props.showIcon ? <img src={icon} alt="button icon right" width="20" height="20" /> : <></>}
        </StyledAPrimary>
      }
      
    </>
  )
}

export default ButtonLinkExternal