import React from 'react'
import FeatureBox from './FeatureBox'
import styled from 'styled-components'
import delivery from '../../../Assets/deliveryIcon3.svg'
import support from '../../../Assets/supportIcon2.svg'
import reviews from '../../../Assets/reviewsIcon2.svg'
import trust from '../../../Assets/trustIcon.svg'
import quality from '../../../Assets/qualityIcon.svg'
import appIcon from '../../../Assets/appIcon2.svg'

const StyledFeatures = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items:center;
    gap: 32px;
    margin-top: 56px;
    
    @media screen and (max-width: 767px){
        & {
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            row-gap: 24px;
            margin-top: 40px;
        }
    }
`

const Features = () => {
  return (
    <StyledFeatures>
        <FeatureBox 
            icon={delivery}
            heading="Fast Delivery & Easy Payment" 
            text= "Option to choose Payment via Internet banking, debit card, credit card, Paytm, Sodexo card, Paypal for International customers. Wherever you are, we offer timely delivery and International delivery."    
        />
        <FeatureBox 
            icon={support}
            heading="Outstanding Support" 
            text= "Our customer support is 24/7 – users rave about how we don’t rest until every issue is solved to their satisfaction."    
        />
        <FeatureBox 
            icon={reviews}
            heading="Amazing Reviews" 
            text= "Check our reviews to know what our customer experiences."    
        />
        <FeatureBox 
            icon={trust}
            heading="Trustworthy" 
            text= "Starting our journey from 2023 and our business venture is focused on providing aromatic quality spices to customers all over the world."    
        />
        <FeatureBox 
            icon={quality}
            heading="Quality" 
            text= "Our team, who are passionate about spreading the pure spice essence, handpick the best of the best only for you."    
        />
        <FeatureBox 
            icon={appIcon}
            heading="Android & IOS Apps" 
            text= "Spicey Tradition has an android and IOS App for customers to place order fast and easy. Download it Now!"    
        />
    </StyledFeatures>
  )
}

export default Features