import React from 'react'
import FooterList from './FooterList.json';

const FooterLinks = () => {
    return (
        <>
            {FooterList.map((value, index)=>{
                return <div key={value.title + index}> 
                    <h5> {value.title} </h5>
                    <div>
                        {value.links.map(( value, index)=>{
                            return <> <a key={value.title + index} href={value.link}> {value.title} </a> <br/> </>
                        })}
                    </div>
                </div>
            })}
        </>
    )
}

export default FooterLinks