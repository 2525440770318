import React from 'react'
import styled from 'styled-components'

const StyledBox = styled.div`
    display:flex;
    align-items:center;
    gap: 8px;
    h4{
        margin-bottom: 4px;
    }
    img{
        width: 30px;
        height: 30px;
        filter:brightness(10) contrast(10);
    }
    *{
        color:white;
    }

    @media screen and (max-width: 767px){
        & {
            width: 33%;
            flex-direction:column;
        }
        *{
            text-align:center;
            font-size: 0.9rem;
        }
        img{
            width: 25px;
            height: 25px;
        }
        p{
            display:none;
        }
    }
`

const USPBox = (props) => {
  return (
    <StyledBox>
        <img src={props.icon} alt="USP icon" width="20" height="20" />
        <div>
            <h4> {props.heading} </h4>
            <p> {props.text} </p>
        </div>
    </StyledBox>
  )
}

export default USPBox