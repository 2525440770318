import React from "react";
import styled from "styled-components";
import USPBox from "./USPBox";
import location from "../../../Assets/locationIcon.svg"
import globe from "../../../Assets/globeIcon.svg"
import lock from "../../../Assets/lockIcon.svg"

const StyledUSP = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    max-width: 1200px;
    margin:auto;
    padding: 20px 0;

    @media screen and (max-width: 767px){
      align-items:flex-start;
      gap: 8px;
    }
`;

const SectionUSP = () => {
    return (
      <div style={{background: "#E46429"}}>
        <div className="maxWidth">
          <StyledUSP>
            <USPBox
              icon={location}
              heading="Free delivery across India"
              text="On all orders above  ₹1500"
              />
            <USPBox
              icon={globe}
              heading="We ship internationally"
              text="To all countries"
              />
            <USPBox
              icon={lock}
              heading="100% secure checkout"
              text="PayPal / Master Card / Visa / UPI"
              />
          </StyledUSP>
        </div>
      </div>
    );
};

export default SectionUSP;
