import './App.css';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import SectionA from './components/Sections/SectionA';
import SectionB from './components/Sections/SectionB';
import SectionC from './components/Sections/SectionC/SectionC';
import SectionUSP from './components/Sections/SectionUSP/SectionUSP';

function App() {

  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <SectionUSP/>
      <SectionA/>
      <SectionB/>
      <SectionC/>
      <Footer/>
    </div>
  );
}

export default App;
