import React from 'react'
import styled from 'styled-components'


const StyledBox = styled.div`
    text-align:center;
    max-width: 400px;
    h4{
        margin-bottom: 16px;
        color:#B01F24;
        font-size: 1.2rem;
    }
    p{
        color:#6A6565;
    }
    img{
        width: 56px;
        height: 46px;
        margin-bottom: 8px;
    }

    @media screen and (max-width: 767px){
        img{
            width: 46px;
            height: 36px;
        }
        p{
            font-size: 0.9rem;
        }
        h4{
            margin-bottom: 8px;
        }
    }
`

const FeatureBox = (props) => {
    console.log(props.icon)
    return (
        <StyledBox>
            {props.icon? <img src={props.icon} alt='feature icon' width="56" height="46" /> : <></> }
            <h4> {props.heading} </h4>
            <p> {props.text} </p>
        </StyledBox>
    )
}

export default FeatureBox