import React from 'react'
import styled from 'styled-components'
import logo from './logo.webp' 
import Navlinks from './Navlinks'
import TopBar from './TopBar'

const StyledNav = styled.div`
    width: 100%;
`

const Navbar = () => {
  return (
    <>
      <TopBar/>
      <StyledNav className='maxWidth'>
          <img src={logo} alt="" className='mainLogo' />
      </StyledNav>
      <Navlinks/>
    </>
  )
}

export default Navbar