import React from 'react'
import styled from 'styled-components'
import ButtonLinkExternal from '../ButtonLinkExternal'

const StyledProductCard = styled.div`
    text-align:center;
    max-width: 250px;
    width: 100%;
    a:first-child{
      transition: box-shadow 0.2s, opacity 0.2s;
      padding: 16px;
      padding-bottom: 16px;
      display:flex;
      flex-direction: column;
      justify-content:space-between;
      border-radius: 8px;
      border: 1px solid #ededed;
      text-decoration:none;
      color: black;
      gap: 16px;
      height: 100%;
      background: #fbfbfb;
    }
    a:first-child:hover{
      box-shadow:  0px 0px 20px #e8e8e8;
      opacity: 1;
    }
    img{
        width: 100%;
        height: 200px;
        max-height: 200px;
        max-width: 200px;
        object-fit:contain;
        margin-bottom: 16px;
    }
    h3{
      margin: 8px 0;
    }
    h6, h4{
      font-weight: 400;
      text-transform: uppercase;
    }
    a.buyNowBtn{
      margin-top: 16px;
    }
    @media screen and (max-width: 767px){
      img{
        height: 150px;
      }
    }
`

const ProductCard = (props) => {
  return (
    <StyledProductCard>
        <a href={props.link}>
        <div>
          <img src={props.imgLink} width="200" height="200" alt={props.name + "Product Image"} />
          <div className='productInfo'>
              <h6> {props.category} </h6>
              <h3> {props.name} </h3>
              <h4> {props.price} </h4>
          </div>
        </div>
        <ButtonLinkExternal className="buyNowBtn" link={props.link} > Buy Now </ButtonLinkExternal>
      </a>
    </StyledProductCard>
  )
}

export default ProductCard