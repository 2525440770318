import React from "react";
import styled from "styled-components";
import Heading from "../../Common/Heading";
import Features from "./Features";

const StyledSection = styled.div`
    padding: var(--padVertical) 0;
    .aboutBox{
        background:#E46429;
        border-radius: 16px;
        padding: 24px;
        max-width: 900px;
        margin:auto;
        color:white;
        text-align:center;
        font-weight: 600;
        font-size: 18px;
        line-height: 135%;
    }
    @media screen and (max-width: 767px){
        .aboutBox{
            padding: 16px;
        }
    }
    `

const SectionC = () => {
    
    return(
        <div>
            <div className="maxWidth">
                <StyledSection>
                    <Heading>
                        Why Shop With Us?
                    </Heading>
                    <div className="aboutBox">
                        We sell authentic and high-quality spices at an affordable rate. Spicey Tradition is the best and top online seller of spices and herbs based on ratings and reviews by customers of Spicey Tradition.
                    </div>
                    <Features/>
                </StyledSection>
            </div>
        </div>
    );
};

export default SectionC;
