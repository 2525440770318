import React from 'react'
import styled from 'styled-components'
import linkList from './Links.json'

const StyledNavLinks = styled.div`
    a{
        color:white;
        text-decoration:none;
        transition: opacity 0.2s;
    }
    a:hover{
        opacity: 0.8;
    }
    display:flex;
    color: white;
    gap: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    @media screen and (max-width: 767px){
        & {
            display:none;
        }
    }
`

const Navlinks = () => {
  return (
    <div style={{background: "var(--red)"}}>
        <StyledNavLinks className='maxWidth'>
            {linkList.map((value, index)=>{
               return <a key={index} href={value.link}> {value.title} </a>
            })}
        </StyledNavLinks>
    </div>
  )
}

export default Navlinks