import React from 'react'
import styled from 'styled-components'
import footerLogo from '../../Assets/spiceyLogo.webp'
import FooterLinks from './FooterLinks'

const StyledFooter = styled.div`
    padding: var(--padVertical) 0;
    display:flex;
    gap: 24px;
    .footerText{
        width: 35%;
        p{
            color:rgba(255,255,255,0.9);
            margin-top: 8px;
            line-height: 135%;
            max-width: 380px;
        }
    }
    .footerLinks{
        width: 65%;
        display:grid;
        grid-template-columns:1fr 1fr 1fr;
        gap: 16px;
    }
    .footerLinks a{
        color:rgba(255,255,255,0.8);
        text-decoration:none;
        margin-bottom: 8px;
    }
    .footerLinks h5{
        font-size: 1rem;
        color:white;
        margin-bottom: 12px;
    }
    
    @media screen and (max-width: 767px){
        & {
            flex-direction:column;
        }
        .footerText{
            width: 100%;
        }
        .footerLinks{
            grid-template-columns: 1fr 1fr;
            width: 100%;
        }
        .footerLinks div:last-child{
            order:-1;
        }
    }
`

const Footer = () => {

    return (
        <div style={{background: "#861619"}}>
            <div className='maxWidth'>
                <StyledFooter>
                    <div className='footerText'>
                        <img src={footerLogo} alt="spicey tradition logo" width="290" height="88" />
                        <p>
                        Spicey Tradition is an innovative Indian spice startup dedicated to bringing the rich, authentic flavors of India's diverse culinary traditions to your kitchen.
                        </p>
                    </div>
                    <div className='footerLinks'>
                        <FooterLinks/>
                    </div>
                </StyledFooter>
            </div>
        </div>
    )
}

export default Footer