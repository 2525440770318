import React from 'react'
import Banner from './heroImage.webp'
import styled from 'styled-components'
import ButtonLinkExternal from '../Common/ButtonLinkExternal'

const StyledHero = styled.div`
    padding: 200px 0px 80px 0;
    position:relative;
    text-align:right;
    .overlay{
        position:absolute;
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%);
        z-index:1;
    }
    > img{
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        z-index:0; 
        object-fit:cover;
        // filter:brightness(0.8)
    }
    > div > div{
        z-index:1;
        position:relative;
        width:fit-content;
        margin-left:auto;
        text-align:left;
    }
    h1{
        color:white;
        font-size: 4rem;
        max-width: 500px;
        font-weight:400;
        font-family: "Italiana", sans-serif;
        letter-spacing:2px;
        margin-bottom: 16px;
    }

    @media screen and (max-width: 767px){
        padding: 100px 0 60px 0;
        h1{
            font-size: 3.4rem;
        }
    }
`

const Hero = () => {
  return (
    <div style={{background: "var(--red)"}}>
        <div>
            <StyledHero>
                <div className='overlay'></div>
                <img src={Banner} width="1440" height="476" alt='Hero Banner Spices' />
                <div className='maxWidth'>
                    <div>
                        <h1> Your next go to place for spices </h1>
                        <ButtonLinkExternal showIcon link="https://www.google.com/" > Shop Now </ButtonLinkExternal>
                    </div>
                </div>
            </StyledHero>
        </div>
    </div>
  )
}

export default Hero